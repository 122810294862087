<template>
	<div class="case-info">
		
    <div class="info-item">
      <ul>
        <li class="title"><span class="subject">{{info.subjectName}}</span>{{info.title}}</li>
        <li>布置时间：{{info.startTime}}</li>
        <li>截至时间：{{info.blockingTime}}</li>
      </ul>
    </div>
		<div class="clear" />
	</div>
</template>

<script>
	import { reactive, toRefs } from 'vue'
	// import { examinationDate, remainingTime } from '@/common/js/utils'
	export default {
		props: {
			info: {
				type: Object,
				default: () => {}
			}
		},
		setup(props) {
			const state = reactive({
        showDesc: false,
				caseInfo: props.info
			})
			
			return {
				...toRefs(state)
			}
		}
	}
</script>

<style lang="less" scoped >
	@import '@/common/style/mixin.less';
	
	.case-info{
		width: calc(100% - 20px);
		height: auto;
		padding: 10px;
		background-color: @fc;
		border-radius: 8px;
		overflow: hidden;
		.bs();
		position: relative;
		
		.info-item{
			width: 90%;
			li{
				line-height: 20px;
			}
      
      .title{
        font-size: 10px;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        .subject{
          padding: 3px 4px;
          margin-right: 4px;
          border-radius: 4px;
          color: #fff;
          background-color: #ff976a;
          
        }
      }
		}
	}
	
	/* 竖屏 */
	@media screen and (orientation: portrait){
		
	}
	
	/* 横屏 */
	@media screen and (orientation: landscape){
		
	}
</style>
