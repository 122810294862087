<template>
  <ul class="list">
    <div class="listIcon">
      <van-image
        width="20px"
        height="20px"
        :src="require('@/assets/images/list.png')"
      />
      <h2>学案列表</h2>
    </div>

    <li v-for="item in list" :key="item.id" @click="doCase(item.id)">
      <h2 class="caseTitle">{{ item.title }}</h2>
      <p class="subjectAndTree">
        {{ item.bookName ? item.bookName : "自定义教材" }}/{{
          item.tree_fullname ? item.tree_fullname : "自定义目录"
        }}
      </p>

      <div class="info">
        <div class="subAndClass">
          <p>
            <span class="infoIndex">学科：</span
            ><span class="subject">{{ item.subjectName }}</span>
          </p>
          <p>
            <span class="infoIndex">班级：</span
            ><span>{{ item.className }}</span>
          </p>
        </div>
        <div class="reAndDo">
          <p>
            <span class="infoIndex">是否已批阅：</span
            ><span
              :style="{ color: item.is_review_student ? 'green' : 'red' }"
              >{{ item.is_review_student ? "是" : "否" }}</span
            >
          </p>
          <p>
            <span class="infoIndex">做题进度：</span
            ><span>{{ item.saTotal }}/{{ item.qaTotal }}</span>
          </p>
        </div>
        <!-- <div class="infoBox">
          <span class="infoIndex">学科：</span>
          <span class="subject">{{ item.subjectName }}</span>
          <span class="infoIndex">是否已批阅：</span>
          <span :style="{ color: item.is_review_student ? 'green' : 'red' }"
            >{{ item.is_review_student ? "是" : "否" }}
          </span>
        </div>

        <div class="infoBox">
          <span class="infoIndex">班级：</span>
          <span>{{ item.className }}</span>
          <span class="infoIndex">做题进度：</span>
          <span>{{ item.saTotal }}/{{ item.qaTotal }}</span>
        </div> -->
      </div>

      <div>
        <span style="color: #666">学案时间：</span>
        <span>{{ item.startTime }} 至 {{ item.blockingTime }}</span>
      </div>

      <div class="fnBtn">
        <van-button
          type="primary"
          size="mini"
          :color="
            item.is_evaluate || item.qaTotal == item.saTotal
              ? 'rgb(132,198,73)'
              : 'rgb(177,189,189)'
          "
          round
          @click.stop="selEvalFn(item.id, item.qaTotal, item.saTotal)"
        >
          {{ item.is_evaluate ? "查看自我评价" : "自我评价" }}
        </van-button>
        <van-button
          type="primary"
          size="mini"
          :color="item.review_str ? 'rgb(76,160,156)' : 'rgb(177,189,189)'"
          @click.stop="remark(item.id, item.review_str)"
          round
          >老师评语</van-button
        >
        <van-button
          type="primary"
          size="mini"
          color="rgb(217,58,69)"
          round
          @click.stop="caseRes(item.id)"
          >教学资料</van-button
        >
      </div>
    </li>
    <div class="nothing">
      <van-empty
        v-if="!list.length"
        :image="require('@/assets/images/kong.png')"
        image-size="80"
        description="暂时没有学案"
      />
    </div>
  </ul>
</template>

<script setup>
import { Toast } from "vant";
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
defineProps(["list"]);

const caseRes = (id) => {
  router.push({ path: "/student/caseRes", query: { case_id: id } });
};

const selEvalFn = (id, iqaTotal, saTotal) => {
  if (iqaTotal == saTotal) {
    router.push({ path: "/student/selfEval", query: { case_id: id } });
  } else {
    Toast.fail("完成当前学案才能进行自我评价哦");
  }
};

const doCase = (id) => {
  router.push({ path: "/student/case/do", query: { id: id } });
};

const remark = (case_id, review_str) => {
  if (review_str) {
    router.push({ path: "/student/teacherRemark", query: { case_id } });
  } else {
    Toast.fail("老师未作评语");
  }
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";
.listIcon {
  display: flex;
  margin-bottom: 10px;
  h2 {
    margin: 0;
    font-size: 10px;
    margin-left: 10px;
  }
}
.list {
  padding: 10px;
  li {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 12px;
    border-radius: 8px;
    .bs();
    h2 {
      margin: 0;
      font-size: 12px;
    }

    .subjectAndTree {
      color: #666;
    }

    .infoIndex {
      color: #777;
    }
    .infoIndex {
      background-color: #fff;
    }
    .subject {
      padding: 0 7px;
      border-radius: 10px;
      background-color: rgb(191, 109, 67);
      color: #fff;
    }

    .fnBtn {
      display: flex;
      justify-content: end;
      :deep(.van-button) {
        margin-left: 10px;
        padding: 0 15px;
      }
    }
  }
}

:deep(.van-divider) {
  margin: 0;
  margin-bottom: 10px;
}

.info {
  display: flex;

  .subAndClass {
    width: 50%;
    p {
      display: flex;
      justify-content: space-between;
      padding-right: 70px;
    }
    p:nth-child(2) {
      padding-right: 80px;
    }
  }

  .reAndDo {
    flex: 1;
    p {
      display: flex;
      justify-content: space-between;
    }
  }
}

.nothing {
  background-color: #fff;
  border-radius: 8px;
}
</style>
