<template>
  <div class="about">
    <s-header :hasBack="true" :title="'学案详情'" />
    <n-bar :userType="'student'" :activeItemName="'index'" />
    <support />

    <div class="pageContent">
      <div class="modular-base">
        <div class="modular-item">
          <c-info :info="caseInfo" />
        </div>
        <div class="modular-item">
          <q-nav :list="navList" :column="3" @callback="navCallBack" />
        </div>
      </div>
      <div class="modular-content">
        <div class="modular-item swipe-box">
          <div class="caseIndex">
            第{{ qsCurrent + 1 }}题/共{{ questions.length }}题
          </div>
          <q-swipe
            :list="questions"
            :current="qsCurrent"
            :is_finished="caseInfo.is_finished"
            :teacherCorrection="teacherCorrection"
            @callbackChanePage="callbackChanePage"
            @changeCaseMassage="changeCaseMassage"
            @changeMsgImgPath="changeMsgImgPath"
            @selectAnswer="selectAnswer"
            @selectAnswer2="selectAnswer2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { secondsToIS, prefix, prefixFile } from "@/common/js/utils";
import { Toast, Dialog } from "vant";
import {
  apiGetCaseInfo,
  apiGetCaseQuestions,
  apiPostAnswer,
  apiPostAnswer2,
  apiFinishCase,
  apiGetCorrection,
} from "@/service/student";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import cInfo from "@/components/student/CaseInfo";
import qNav from "@/components/QuickNav";
import qSwipe from "@/components/student/QuestionSwipe";

export default {
  components: {
    sHeader,
    nBar,
    support,
    cInfo,
    qNav,
    qSwipe,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const state = reactive({
      navList: [
        {
          icon: "star-o",
          iconColor: "#ffaa00",
          text: "关注此题",
          badge: "",
          callback: "follow",
        },
        {
          icon: require("@/assets/images/u1448.png"),
          text: "我要提问",
          badge: "",
          routeName: "",
        },
        {
          icon: "clock-o",
          text: "计时器",
          badge: "",
          isClock: true,
        },
      ],
      caseInfo: {},
      questions: [],
      timer: null,
      caseId: null,
      qsCurrent: 0,
    });

    onMounted(async () => {
      state.caseId = route.query.id;

      await getCaseInfo();
      await getQuestions();
      // await getMassageInfo(state.questions[state.qsCurrent].id);
      await getCorrection();
    });

    const navCallBack = (item) => {
      switch (item.callback) {
        case "follow":
          // let formData = new FormData();
          // formData.append('caseId', state.caseId)
          // formData.append('questionId', state.questions[state.qsCurrent].id)
          // const apiRes = await apiFollowQuestion(formData)
          Toast.fail("功能未开放");
          break;
      }
    };

    const checkFollow = async () => {
      if (state.questions[state.qsCurrent].isFollow) {
        state.navList[0].icon = "star";
        state.navList[0].text = "已关注";
      } else {
        state.navList[0].icon = "star-o";
        state.navList[0].text = "关注此题";
      }
    };

    const getCaseInfo = async () => {
      const apiRes = await apiGetCaseInfo({ caseId: state.caseId });
      if (apiRes) {
        state.caseInfo = apiRes.data;
        const totalTimeObj = secondsToIS(state.caseInfo.totalTime);
        state.navList[2].text = totalTimeObj.chinese;
      }
    };

    const getQuestions = async () => {
      const apiRes = await apiGetCaseQuestions({ caseId: state.caseId });
      if (apiRes) {
        apiRes.data.forEach(async (item, index) => {
          if (item.remarks) {
            item.remarks.remarksList = item.remarks.image_path
              ? [{ url: prefix(item.remarks.image_path) }]
              : [];
          } else {
            item.remarks = { remarksList: [], remarks: "", image_path: "" };
          }

          if (!item.studentAnswer) {
            item.studentAnswer = {
              answer: "",
              answerUrl: "",
              answer2: "",
              answer2Url: "",
              comprehension: "1",
              takeTime: 0,
              prier: 0,
              fileArr: [],
              fileArr2: [],
            };
          }
          item.studentAnswer.comprehension =
            item.studentAnswer.comprehension == 1 ? "1" : "0";

          if (item.way == "1") {
            item.question = item.question.replace(
              "<img ",
              "<img @click.stop='openPreview('',item.question)' style = 'width:100%; height:100%'"
            );
          }

          if (item.way == "2") {
            item.question = prefix(item.question);
            if (item.type == "3") {
              item.answer = prefix(item.answer);
            }
          }
          if (item.way == "3") {
            item.question =
              item.file_ext == "pdf" || item.file_ext == "txt"
                ? prefix(item.question)
                : prefixFile(prefix(item.question));
          }
          if (item.type == "3") {
            // item.studentAnswer.answer = item.studentAnswer.answer ? prefix(item.studentAnswer.answer) : item.studentAnswer.answer
            item.studentAnswer.answerUrl = item.studentAnswer.answer
              ? prefix(item.studentAnswer.answer)
              : item.studentAnswer.answer;
            (item.studentAnswer.fileArr = item.studentAnswer.answerUrl
              ? [item.studentAnswer.answerUrl]
              : []),
              (item.studentAnswer.answer2Url = item.studentAnswer.answer2
                ? prefix(item.studentAnswer.answer2)
                : item.studentAnswer.answer2);
            item.studentAnswer.fileArr2 = item.studentAnswer.answer2Url
              ? [item.studentAnswer.answer2Url]
              : [];
          }

          if (item.answer_type == 2) {
            item.answer = prefix(item.answer);
          }
          if (item.answer_type == 3) {
            item.answer =
              item.answer_file_ext == "pdf" || item.answer_file_ext == "txt"
                ? prefix(item.answer)
                : prefixFile(prefix(item.answer));
          }
          if (item.select_type_id == 2) {
            item.studentAnswer.answer = item.studentAnswer.answer
              ? item.studentAnswer.answer.split(",")
              : [];
            item.studentAnswer.answer2 = item.studentAnswer.answer2
              ? item.studentAnswer.answer2.split(",")
              : [];
          }

          item.analysis = item.analysis ? prefix(item.analysis) : "";

          state.questions.push(item);
          if (index == 0) {
            await checkFollow();
          }
        });

        await setTimer("start");
      }
    };

    const setTimer = async (type) => {
      if (type == "start") {
        state.timer = setInterval(() => {
          if (state.caseInfo.is_finished != "1") {
            state.questions[state.qsCurrent].studentAnswer.takeTime++;
            state.caseInfo.totalTime++;
            const totalTimeObj = secondsToIS(state.caseInfo.totalTime);
            state.navList[2].text = totalTimeObj.chinese;
          }
        }, 1000);
      }
      if (type == "clear") {
        clearInterval(state.timer);
      }
    };

    const callbackChanePage = async (type) => {
      let _scroll = document.querySelector(".swipe-box");
      _scroll.scrollTop = 0;

      setTimer("clear");
      if (type == "jump") {
        state.qsCurrent++;
        checkFollow();
        setTimer("start");
        await getCorrection();
      }

      //上一页
      if (type == "prev") {
        state.qsCurrent--;
        checkFollow();
        setTimer("start");
        await getCorrection();
      }

      // 下一题
      if (type == "nextCase") {
        console.log(state.questions[state.qsCurrent]);
        await getCorrection();
      }

      // 保存并下一题
      if (type == "next") {
        const qitem = state.questions[state.qsCurrent];
        if (
          qitem.studentAnswer.answer == "" &&
          state.caseInfo.is_finished == 0
        ) {
          Toast.fail("请作答后，再进行下一题!");
          return false;
        }

        // if(qitem.studentAnswer.answer2 == '' && state.caseInfo.is_finished == 1){
        //   Toast.fail('请修正后，再进行下一题!')
        //   return false
        // }

        // Dialog.confirm({
        //   title: "是否进入下一题？",
        //   message: "进入下一题会保存当前题目数据，保存后无法修改",
        // })
        //   .then(async () => {
        let isSave = await postAnswer();
        if (isSave) {
          state.qsCurrent++;
          checkFollow();
          setTimer("start");
          await getCorrection();
          // } else if (isSave == false) {
          //   Toast.fail("请勿重复提交");
        }
        // })
        // .catch(() => {});
      }

      if (type == "finished") {
        Dialog.confirm({
          title: "是否完成当前学案？",
          message: "会保存当前题目数据，保存后无法修改",
        })
          .then(async () => {
            const qitem = state.questions[state.qsCurrent];
            if (
              qitem.studentAnswer.answer == "" &&
              state.caseInfo.is_finished == 0
            ) {
              Toast.fail("请作答后，再完成!");
              return false;
            }

            const isSave = await postAnswer();
            if (isSave) {
              const isFinish = await finishCase();
              if (isFinish) {
                Toast.success("学案已完成");
                router.go(-1);
              }
            }
          })
          .catch(() => {});
      }

      // await getMassageInfo(state.questions[state.qsCurrent].id);
    };

    // 学案备注
    // let caseMassage = ref({});

    // const getMassageInfo = async (question_id) => {
    //   let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //   console.log(question_id);
    //   let params = {
    //     case_id: route.query.id,
    //     question_id,
    //     studentsNo: userInfo.studentsNo,
    //   };
    //   let res = await apiStudentRemarksInfo(params);
    //   res.data.imgList = res.data.image_path
    //     ? [{ url: prefix(res.data.image_path) }]
    //     : [];
    //   res.data.imgPath = res.data.image_path ? res.data.image_path : "";
    //   caseMassage.value = res.data;
    // };

    // 修改备注
    const changeCaseMassage = (val) => {
      state.questions[state.qsCurrent].remarks.remarksList = val.url;
    };

    const postAnswer = async () => {
      const qitem = state.questions[state.qsCurrent];
      let formData = new FormData();
      formData.append(
        "answer",
        qitem.studentAnswer.answer ? qitem.studentAnswer.answer : ""
      );
      formData.append(
        "answer2",
        qitem.studentAnswer.answer2 ? qitem.studentAnswer.answer2 : ""
      );
      formData.append("takeTime", qitem.studentAnswer.takeTime);
      formData.append("comprehension", qitem.studentAnswer.comprehension);
      formData.append("caseId", state.caseId);
      formData.append("questionId", qitem.id);

      if (qitem.studentAnswer.prier == 0) {
        const apiRes = await apiPostAnswer(formData);
        if (apiRes) {
          state.questions[state.qsCurrent].studentAnswer.prier = 1;
          return true;
        } else {
          return false;
        }
      } else {
        const apiRes = await apiPostAnswer2(formData);
        if (apiRes) {
          state.questions[state.qsCurrent].studentAnswer.prier = 2;
          return true;
        } else {
          return false;
        }
        // } else {
        //   return false;
      }
    };

    const finishCase = async () => {
      let formData = new FormData();
      formData.append("caseId", state.caseId);
      const apiRes = await apiFinishCase(formData);
      if (apiRes) {
        return true;
      } else {
        return false;
      }
    };

    const changeMsgImgPath = (url) => {
      if (url) {
        state.questions[state.qsCurrent].remarks.image_path = url;
        state.questions[state.qsCurrent].remarks.remarksList = [
          { url: prefix(url) },
        ];
      } else {
        state.questions[state.qsCurrent].remarks.image_path = "";
      }
    };

    const selectAnswer = (val) => {
      state.questions[state.qsCurrent].studentAnswer.answer = val;
    };
    const selectAnswer2 = (val) => {
      state.questions[state.qsCurrent].studentAnswer.answer2 = val;
    };

    let teacherCorrection = ref("");
    const getCorrection = async () => {
      let res = await apiGetCorrection({
        answer_id: state.questions[state.qsCurrent].studentAnswer.id,
      });
      if (res) {
        teacherCorrection.value = res.data.fileUrl
          ? prefix(res.data.fileUrl)
          : "";
      }
    };

    return {
      ...toRefs(state),
      navCallBack,
      callbackChanePage,
      // getMassageInfo,
      // caseMassage,
      changeCaseMassage,
      // userInfo
      changeMsgImgPath,
      selectAnswer,
      selectAnswer2,
      getCorrection,
      teacherCorrection,
    };
  },
};
</script>

<style lang="less" scoped>
@import "@/common/style/pageContent.less";

.swipe-box {
  padding: 10px;
}

.caseIndex {
  font-size: 12px;
  font-weight: 500;
  padding: 0 0 10px 10px;
}
</style>
