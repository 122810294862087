<template>
  <div class="about">
    <s-header :hasBack="true" :title="'学案'" />
    <n-bar :userType="'student'" :activeItemName="'index'" />
    <support :otherClass="'width40'" />

    <div class="pageContent">
      <div class="modular-base">
        <div class="modular-item">
          <div class="nav">
            <ul class="subject">
              <li><h2 class="title">科目</h2></li>
              <li>
                <div
                  class="all"
                  :class="subIndex == -1 ? 'active' : ''"
                  @click="clickAllSubject"
                >
                  全部
                </div>
              </li>
              <li v-for="(item, index) in subjectList" :key="item.id">
                <div
                  class="subjectNameBox"
                  :class="index == subIndex ? 'active' : ''"
                  :style="{ boxShadow: `0 0 0 0.5px ${item.color}` }"
                  @click="subjectSearch(item.id, index)"
                >
                  {{ item.subjectName }}
                </div>
              </li>
            </ul>
            <ul class="statute">
              <li><h2 class="title">状态</h2></li>
              <li>
                <div
                  class="all"
                  :class="staIndex == -1 ? 'staActive' : ''"
                  @click="clickAllSta"
                >
                  全部
                </div>
              </li>
              <li v-for="(item, index) in statute" :key="item">
                <div
                  class="statuteBox"
                  :style="{ boxShadow: `0 0 0 0.5px ${item.color}` }"
                  :class="index == staIndex ? 'staActive' : ''"
                  @click="statusSearch(item.id, index)"
                >
                  {{ item.name }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modular-content">
        <div class="modular-item">
          <van-list
            v-model:loading="caseLoading"
            :finished="caseLoadingFinished"
            :finished-text="caseList.length ? '没有更多了' : ''"
            @load="getCaseList"
          >
            <sc-list
              :showLine="true"
              lineText="列表"
              lineTextPosition="left"
              listType="common"
              :list="caseList"
            />
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { secondsToIS } from "@/common/js/utils";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import scList from "@/components/student/CaseList";
import { apiGetSubjectsList } from "@/service/subject";
// import { apiGetBooksList, apiGetBookTree } from "@/service/book";
import { apiGetCasesList, apiGetCaseStatus } from "@/service/student";

let userInfo = JSON.parse(localStorage.getItem("userInfo"));
onBeforeMount(async () => {
  await getSubjectList();
  await getStatus();
});

let caseList = ref([]);
let caseLoading = ref(false);
let caseLoadingFinished = ref(false);
let casePage = ref(0);
let caseLimit = ref(10);
let subjectId = ref(0);
let bookId = ref(0);
let status = ref(0);

const getCaseList = async () => {
  caseLoading.value = true;
  caseLoadingFinished.value = false;
  casePage.value++;

  const params = {
    page: casePage.value,
    limit: caseLimit.value,
    searchTitle: "",
    subjectId: subjectId.value,
    bookId: bookId.value,
    chapterTreeId: "",
    isToday: 0,
    status: status.value,
    // appointedDate: '2022-07-19',
  };

  const apiRes = await apiGetCasesList(params);
  if (apiRes) {
    for (let i = 0; i < apiRes.data.length; i++) {
      let item = apiRes.data[i];
      const totalTimeObj = secondsToIS(item.totalTime);
      item.totalTimeStr = totalTimeObj.chinese;
      caseList.value.push(item);
    }
    caseLoading.value = false;
    if (apiRes.data.length < caseLimit.value) {
      caseLoadingFinished.value = true;
    }
  } else {
    caseLoading.value = false;
    caseLoadingFinished.value = true;
  }
};

let statute = ref([]);

let subjectList = ref([]);
const getSubjectList = async () => {
  let res = await apiGetSubjectsList({ gradeId: userInfo.gradeID });
  if (res) {
    subjectList.value = res.data;
  }
};
const getStatus = async () => {
  let res = await apiGetCaseStatus();
  if (res) {
    statute.value = res.data;
  }
};

const clickAllSubject = async () => {
  subIndex.value = -1;
  casePage.value = 0;
  caseList.value = [];
  subjectId.value = 0;
  await getCaseList();
};

// 点击学科筛选
let subIndex = ref(-1);
const subjectSearch = async (id, index) => {
  subIndex.value = index;
  subjectId.value = id;
  casePage.value = 0;
  caseList.value = [];
  await getCaseList();
};

const clickAllSta = async () => {
  staIndex.value = -1;
  casePage.value = 0;
  status.value = 0;
  caseList.value = [];
  await getCaseList();
};

// 点击状态筛选
let staIndex = ref(-1);
const statusSearch = async (id, index) => {
  staIndex.value = index;
  casePage.value = 0;
  status.value = id;
  caseList.value = [];
  await getCaseList();
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";

.nav {
  display: flex;
  font-size: 12px;

  .subject {
    margin-right: 20px;
  }
  .subject,
  .statute {
    text-align: center;
    .title {
      margin: 0;
      font-size: 10px;
      color: rgb(197, 171, 171);
    }
    li {
      margin-bottom: 10px;
    }

    .active {
      color: #fff;
      background-color: rgb(102, 204, 255);
      box-shadow: none;
      font-weight: 500;
    }

    .staActive {
      color: #fff;
      background-color: rgb(102, 204, 255);
      box-shadow: none;
      font-weight: 500;
    }
  }
  .all {
    box-shadow: 0 0 0 0.5px red;
    padding: 5px;
    border-radius: 5px;
  }

  .subjectNameBox {
    box-shadow: 0 0 0 0.5px red;
    padding: 5px;
    border-radius: 5px;
  }

  .statuteBox {
    padding: 5px;
    border-radius: 5px;
  }
}

/* 竖屏 */
@media screen and (orientation: portrait) {
  .modular-base {
    width: 180px;
    height: auto;
    margin: 10px;
    position: fixed;
    top: 30px;
    left: 0;
    bottom: 40px;
    overflow-y: auto;

    .modular-item {
      padding: 4px;
      margin-bottom: 5px;
    }
  }

  .modular-content {
    margin-left: 180px;
    padding-left: 10px;
    height: auto;
    min-height: calc(100vh - 30px - 40px);
    // background-color: @fc;
  }
}

/* 横屏 */
@media screen and (orientation: landscape) {
  .modular-base {
    width: 170px;
    height: auto;
    margin: 6px 10px 10px 10px;
    position: fixed;
    top: 30px;
    left: 50px;
    bottom: 20px;
    overflow-y: auto;

    .modular-item {
      padding: 4px;
      margin-bottom: 5px;
    }
  }

  .modular-content {
    margin-left: 167px;
    height: auto;
    min-height: calc(100vh - 30px - 20px);
    // background-color: @fc;
  }
}

:deep(.van-list__finished-text) {
  padding-bottom: 30px;
}
</style>
