<template>
  <div class="qsList">
    <van-swipe
      ref="swipe"
      :loop="false"
      :initial-swipe="current"
      :touchable="false"
      :show-indicators="false"
    >
      <van-swipe-item v-for="(item, i) in questionList" :key="i">
        <div class="swipe-content">
          <div class="qa-area">
            <div :class="['qa-area-content', item.way == 3 ? 'forFile' : '']">
              <div
                class="question-text"
                v-if="item.way == 1"
                @click="showText(i)"
              >
                <div v-html="item.question"></div>
                <!-- {{  }} -->
              </div>
              <div class="imgBox" v-if="item.way == 2">
                <van-image
                  width="100%"
                  height="100%"
                  :src="item.question"
                  @click="openPreview('学案题目', item.question)"
                />
              </div>
              <div class="question-file" v-if="item.way == 3">
                <iframe width="100%" height="100%" :src="item.question" />
              </div>
              <div
                class="title positionR"
                v-if="item.way == 2"
                close-on-click-action
                @click="openPreview('', item.question)"
              >
                放大预览
              </div>
              <div class="title positionL">学案题目</div>
            </div>

            <!-- <div
              class="qa-area-content"
              v-if="
                item.way == 1 &&
                item.type != 3 &&
                item.question_option.length > 0 &&
                Object.prototype.toString.call(item.question_option) ==
                  '[object Array]'
              "
            >
              <div class="question-text" @click="showOption(i)">
                <div v-for="(oitem, oi) in item.question_option" :key="oi">
                  {{ oitem.text }}
                </div>
              </div>
              <div class="title positionR">选项</div>
            </div> -->

            <div class="qa-area-content">
              <div class="imgBox" v-if="item.type == 3">
                <van-image
                  v-if="
                    item.studentAnswer.answerUrl && item.studentAnswer.prier
                  "
                  width="100%"
                  height="100%"
                  :src="item.studentAnswer.answerUrl"
                  @click="openPreview('我的答案', item.studentAnswer.answerUrl)"
                />

                <ua-cut
                  v-else
                  ref="uploadAnswer"
                  :isCustomPreview="true"
                  previewType="full"
                  width="100%"
                  height="100%"
                  :fileArr="item.studentAnswer.fileArr"
                  :maxCount="1"
                  :itemIndex="i"
                  keyName="answer"
                  @callbackForCut="callbackForCut"
                  @openPreview="openPreview"
                  @delItem="delItem"
                  capture="camera"
                  btnTips="请点击提交答案!"
                />
              </div>

              <div v-if="item.type == 1 && item.select_type_id == 1">
                <!-- 单选题 -->
                <van-radio-group
                  v-model="item.studentAnswer.answer"
                  direction="horizontal"
                  @change="changeSelect"
                >
                  <van-radio
                    v-for="opItem in item.question_option"
                    :name="opItem.key"
                    :disabled="is_finished > 0"
                    >{{ opItem.key }}</van-radio
                  >
                </van-radio-group>
              </div>
              <!-- 多选 -->
              <div v-if="item.type == 1 && item.select_type_id == 2">
                <van-checkbox-group
                  v-model="item.studentAnswer.answer"
                  direction="horizontal"
                >
                  <van-checkbox
                    v-for="opItem in item.question_option"
                    :name="opItem.key"
                    shape="square"
                    :disabled="is_finished > 0"
                    >{{ opItem.key }}</van-checkbox
                  >
                </van-checkbox-group>
              </div>
              <div v-if="item.type == 1 && !item.question_option.length">
                <van-field
                  v-model="item.studentAnswer.answer"
                  rows="3"
                  label=""
                  type="textarea"
                  placeholder="请输入答案"
                />
              </div>
              <div v-if="item.type == 2">
                <van-field
                  v-model="item.studentAnswer.answer"
                  rows="3"
                  label=""
                  type="textarea"
                  placeholder="请输入答案"
                />
              </div>
              <div class="title positionL">我的答案</div>
            </div>

            <div class="qa-area-content" v-if="is_finished > 0">
              <div class="imgBox" v-if="item.type == 3">
                <!-- <van-image
                  v-if="
                    item.studentAnswer.answer2Url &&
                    item.studentAnswer.prier == 2
                  "
                  width="100%"
                  height="100%"

                  :src="item.studentAnswer.answer2Url"
                  @click="
                    openPreview('我的修正', item.studentAnswer.answer2Url)
                  "
                /> -->

                <ua-cut
                  ref="uploadAnswer"
                  :isCustomPreview="true"
                  previewType="full"
                  width="100%"
                  height="100%"
                  :fileArr="item.studentAnswer.fileArr2"
                  :maxCount="1"
                  :itemIndex="i"
                  keyName="answer2"
                  @callbackForCut="callbackForCut"
                  @openPreview="openPreview"
                  @delItem="delItem"
                  @changeIsShowCro="changeIsShowCro"
                  capture="camera"
                  btnTips="请点击提交修正内容!"
                />
              </div>
              <div v-if="item.type == 1 && item.select_type_id == 1">
                <!-- 单选题 -->
                <van-radio-group
                  v-model="item.studentAnswer.answer2"
                  direction="horizontal"
                  @change="changeSelect2"
                >
                  <van-radio
                    v-for="opItem in item.question_option"
                    :name="opItem.key"
                    >{{ opItem.key }}</van-radio
                  >
                </van-radio-group>
              </div>

              <!-- 多选 -->
              <div v-if="item.type == 1 && item.select_type_id == 2">
                <van-checkbox-group
                  v-model="item.studentAnswer.answer2"
                  direction="horizontal"
                >
                  <van-checkbox
                    v-for="opItem in item.question_option"
                    :name="opItem.key"
                    shape="square"
                    >{{ opItem.key }}</van-checkbox
                  >
                </van-checkbox-group>
              </div>
              <div v-if="item.type == 1 && !item.question_option.length">
                <van-field
                  v-model="item.studentAnswer.answer2"
                  rows="3"
                  label=""
                  type="textarea"
                  placeholder="请输入答案"
                />
              </div>
              <div v-if="item.type == 2">
                <van-field
                  v-model="item.studentAnswer.answer2"
                  rows="3"
                  label=""
                  type="textarea"
                  placeholder="请输入修正内容"
                />
              </div>
              <div class="title positionR">我的修正</div>
            </div>

            <div class="qa-area-content correction" v-if="teacherCorrectionUrl">
              <div class="title positionL">老师批阅</div>
              <van-image
                width="100%"
                height="100%"
                :src="teacherCorrectionUrl"
                @click="openPreview('', teacherCorrectionUrl)"
              />
            </div>

            <div class="qa-area-content messageBox" v-if="item.type == 3">
              <div class="text">
                <van-field
                  v-model="item.remarks.remarks"
                  rows="2"
                  autosize
                  label=""
                  type="textarea"
                  maxlength="300"
                  placeholder="输入备注"
                  show-word-limit
                  @update:model-value="changeMassage"
                />
              </div>
              <div
                class="upImgBox"
                style="overflow: hidden"
                v-if="!item.remarks.image_path"
              >
                <van-uploader
                  :before-read="beforeRead"
                  @click-upload="clickUploader(i)"
                >
                  <div class="icon">
                    <van-icon name="photograph" />
                  </div>
                  <div>附加图片</div>
                </van-uploader>
              </div>
              <div class="upImgBox child" v-if="item.remarks.image_path">
                <div class="crossIcon" @click="closImg">
                  <van-icon name="cross" />
                </div>
                <van-image
                  width="100%"
                  height="100%"
                  :src="item.remarks.remarksList[0].url"
                />
              </div>
              <div style="float: right; margin-top: 10px">
                <van-button
                  type="primary"
                  block
                  size="mini"
                  @click="inputBlur(i)"
                  >保存备注</van-button
                >
              </div>
            </div>

            <van-field name="radio" label="关于此题您是否理解？">
              <template #input>
                <van-radio-group
                  v-model="item.studentAnswer.comprehension"
                  direction="horizontal"
                >
                  <van-radio name="1">理解了</van-radio>
                  <van-radio name="0">还有疑问</van-radio>
                </van-radio-group>
              </template>
            </van-field>
          </div>
          <div class="fun-area">
            <!-- <div v-if="is_finished == 2">
              <div class="btnBox" v-if="i !== questionList.length - 1">
                <van-button
                  type="primary"
                  block
                  size="small"
                  @click="changePage('nextCase')"
                  >下一题</van-button
                >
              </div>
              <div class="btnBox" v-if="i > 0">
                <van-button
                  type="info"
                  block
                  size="small"
                  @click="changePage('prev')"
                  >上一题</van-button
                >
              </div>
              <div class="btnBox" v-if="item.answer">
                <van-button
                  type="success"
                  block
                  size="small"
                  @click="changePage('showAnswer')"
                  >查看答案</van-button
                >
              </div>
            </div> -->
            <div>
              <div class="btnBox" v-if="current < questionList.length - 1">
                <van-button
                  type="warning"
                  block
                  size="small"
                  @click="changePage('jump')"
                  >跳过</van-button
                >
              </div>
              <div class="btnBox" v-if="i === questionList.length - 1">
                <van-button
                  type="primary"
                  block
                  size="small"
                  @click="changePage('finished')"
                  >完成</van-button
                >
              </div>
              <div class="btnBox" v-if="i !== questionList.length - 1">
                <van-button
                  type="primary"
                  block
                  size="small"
                  @click="changePage('next')"
                  >提交并下一题</van-button
                >
              </div>
              <div class="btnBox" v-if="i > 0">
                <van-button
                  type="info"
                  block
                  size="small"
                  @click="changePage('prev')"
                  >上一题</van-button
                >
              </div>
              <div class="btnBox" v-if="item.answer && is_finished == 1">
                <van-button
                  type="success"
                  block
                  size="small"
                  @click="changePage('showAnswer')"
                  >查看答案</van-button
                >
              </div>
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>

    <van-popup
      duration="0"
      v-model:show="showCropper"
      position="top"
      :style="{ height: '100%', width: '100vw' }"
    >
      <div class="cropper-container">
        <!-- <VueCropper
          ref="cropper"
          :img="cutOption.img"
          :outputSize="cutOption.outputSize"
          :outputType="cutOption.outputType"
          :info="cutOption.info"
          :full="cutOption.full"
          :autoCropWidth="cutOption.autoCropWidth"
          :autoCropHeight="cutOption.autoCropHeight"
          :canMove="cutOption.canMove"
          :canMoveBox="cutOption.canMoveBox"
          :original="cutOption.original"
          :autoCrop="cutOption.autoCrop"
          :fixed="cutOption.fixed"
          :fixedNumber="cutOption.fixedNumber"
          :centerBox="cutOption.centerBox"
          :infoTrue="cutOption.infoTrue"
          :fixedBox="cutOption.fixedBox"
          :high="cutOption.high"
          :mode="cutOption.mode"
        ></VueCropper> -->

        <vue-cropper
          v-if="isShowCro"
          :aspect-ratio="option.cropperAspectWH"
          ref="cropper"
          :src="option.imgSrc"
          :ready="option.cropImage"
          :zoom="option.cropImage"
          :cropmove="option.cropImage"
          style="width: 100%; height: 100%"
        ></vue-cropper>
        <div class="fnBtn">
          <van-nav-bar
            left-text="取消"
            right-text="确认"
            @click-left="onClickLeft"
            @click-right="getCropBlob"
          ></van-nav-bar>
        </div>
      </div>
    </van-popup>

    <van-image-preview
      v-model:show="showPreview"
      :images="previewImgs"
      :loop="false"
      :closeable="true"
      :maxZoom="2"
    >
      <template v-slot:index>{{ previewTitle }}</template>
    </van-image-preview>

    <van-overlay :show="showPreText" @click="showPreText = false">
      <div class="descOverlay">
        <div class="block" v-html="preText"></div>
      </div>
    </van-overlay>

    <!-- <van-overlay :show="showOptionText" @click="showOptionText = false">
      <div class="descOverlay">
        <div class="block">{{ optionText }}</div>
      </div>
    </van-overlay> -->

    <van-overlay :show="showFilePreview" @click="showFilePreview = false">
      <div class="fileOverlay">
        <iframe :src="fileUrl" width="100%" height="100%" />
      </div>
    </van-overlay>

    <van-overlay :show="showAnswerPreview" @click="showAnswerPreview = false">
      <div class="descOverlay">
        <div class="block" v-if="questionList[current].answer_type == '2'">
          <van-image
            width="100%"
            height="100%"
            :src="questionList[current].answer"
            @click="openPreview('查看答案', questionList[current].answer)"
          />
          <h3
            style="
              border-bottom: 1px solid #eee;
              border-top: 1px solid #eee;
              line-height: 35px;
            "
          >
            答案解析
          </h3>
          <div v-if="!questionList[current].analysis">
            <van-empty description="无答案解析" />
          </div>
          <van-image
            v-if="questionList[current].analysis"
            width="100%"
            height="100%"
            :src="questionList[current].analysis"
            @click="openPreview('查看答案', questionList[current].analysis)"
          />
        </div>
        <div class="block file" v-if="questionList[current].answer_type == '3'">
          <iframe
            :src="questionList[current].answer"
            width="100%"
            height="100%"
          />
          <h3
            style="
              border-bottom: 1px solid #eee;
              border-top: 1px solid #eee;
              line-height: 35px;
            "
          >
            答案解析
          </h3>
          <div v-if="!questionList[current].analysis">
            <van-empty description="无答案解析" />
          </div>
          <van-image
            v-if="questionList[current].analysis"
            width="100%"
            height="100%"
            :src="questionList[current].analysis"
            @click="openPreview('查看答案', questionList[current].analysis)"
          />
        </div>
        <div class="block" v-if="questionList[current].answer_type == '1'">
          答案：{{ questionList[current].answer }}
          <h3
            style="
              border-bottom: 1px solid #eee;
              border-top: 1px solid #eee;
              line-height: 35px;
            "
          >
            答案解析
          </h3>
          <div v-if="!questionList[current].analysis">
            <van-empty description="无答案解析" />
          </div>
          <van-image
            v-if="questionList[current].analysis"
            width="100%"
            height="100%"
            :src="questionList[current].analysis"
            @click="openPreview('查看答案', questionList[current].analysis)"
          />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

// import "vue-cropper/dist/index.css";
import { ref, reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
// import { VueCropper } from "vue-cropper";
import { prefix } from "@/common/js/utils";
import { uploadImgBase } from "@/service/upload";
import uaCut from "@/components/student/UploadAndCut";
import { apiSaveQuestionRemarks } from "@/service/student.js";

export default {
  components: {
    VueCropper,
    uaCut,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    current: {
      type: Number,
      default: 0,
    },
    is_finished: {
      type: Number,
      default: 0,
    },
    teacherCorrection: {
      type: String,
      default: "",
    },
  },
  emits: [
    "callbackChanePage",
    "changeCaseMassage",
    "changeMsgImgPath",
    "selectAnswer",
    "selectAnswer2",
  ],

  setup(props, ctx) {
    const refProps = toRefs(props);
    const route = useRoute();

    const state = reactive({
      is_finished: refProps.is_finished,
      current: refProps.current,
      questionList: refProps.list,

      showPreview: false,
      previewTitle: "",
      previewImgs: [],

      showPreText: false,
      preText: "",

      showOptionText: false,
      optionText: "",

      showCropper: false,
      cutOption: {
        img: "",
        outputSize: 0.6,
        info: false,
        outputType: "png",
        canScale: true,
        autoCrop: true,
        // autoCropWidth: 1230,
        // autoCropHeight: 1150,
        high: true,
        fixedBox: false,
        fixed: false,
        fixedNumber: [1, 1],
        full: true,
        canMove: true,
        canMoveBox: true,
        original: false,
        centerBox: true,
        infoTrue: false,
        mode: "auto auto",
        detail: null,
        itemIndex: null,
        keyName: null,
      },

      showFilePreview: false,
      fileUrl: "",

      showAnswerPreview: false,

      teacherCorrectionUrl: refProps.teacherCorrection,
    });
    // route.query.question_id = state.questionList;

    let massage = ref("");

    const openPreview = (title, imgSrc) => {
      state.showPreview = true;
      state.previewTitle = title;
      state.previewImgs = [imgSrc];
    };

    const showText = (i) => {
      state.showPreText = true;
      state.preText = state.questionList[i].question;
    };

    const showOption = (i) => {
      state.showOptionText = true;
      let optionText = "";
      for (let o = 0; o < state.questionList[i].question_option.length; o++) {
        optionText =
          optionText + state.questionList[i].question_option[o].text + "\n";
      }

      state.optionText = optionText;
    };

    const callbackForCut = (op) => {
      state.showCropper = true;
      isShowCro.value = true;
      option.value = op;
      state.cutOption = op;
    };

    const onClickLeft = () => {
      state.showCropper = false;
      isShowCro.value = false;
      option.value.imgSrc = "";
    };

    let cropper = ref(null);
    const getCropBlob = async () => {
      // cropper.value.getCropData((data) => {
      state.showCropper = false;
      uploadBlob(cropper.value.src, option.value.itemIndex);
      //   uploadBlob(data, state.cutOption.itemIndex);
      // });
    };

    const uploadBlob = async (fileBase, index) => {
      let formData = new FormData();
      formData.append("imgBase", fileBase);
      const apiRes = await uploadImgBase(formData);
      if (apiRes) {
        if (showBtnType.value) {
          // 备注图片

          imgPath.value = apiRes.data.file.url;
          ctx.emit("changeCaseMassage", {
            url: [{ url: prefix(apiRes.data.file.url) }],
            path: apiRes.data.file.url,
          });
          ctx.emit("changeMsgImgPath", apiRes.data.file.url);
          inputBlur(index);
          showBtnType.value = false;
        } else {
          const url = apiRes.data.file.url;
          const urlFix = prefix(url);
          if (state.cutOption.keyName == "answer") {
            state.questionList[index].studentAnswer.answer = url;
            state.questionList[index].studentAnswer.answerUrl = urlFix;
            state.questionList[index].studentAnswer.fileArr.push(urlFix);
          }
          if (state.cutOption.keyName == "answer2") {
            state.questionList[index].studentAnswer.answer2 = url;
            state.questionList[index].studentAnswer.answer2Url = urlFix;
            state.questionList[index].studentAnswer.fileArr2.push(urlFix);
          }
        }
        isShowCro.value = false;
        option.value.imgSrc = "";
      }
    };

    const delItem = (index, keyName) => {
      if (keyName == "answer") {
        state.questionList[index].studentAnswer.fileArr = [];
        state.questionList[index].studentAnswer.answer = "";
        state.questionList[index].studentAnswer.answerUrl = "";
      }
      if (keyName == "answer2") {
        state.questionList[index].studentAnswer.fileArr2 = [];
        state.questionList[index].studentAnswer.answer2 = "";
        state.questionList[index].studentAnswer.answer2Url = "";
      }
    };

    const changePage = (type) => {
      if (type == "showAnswer") {
        state.showAnswerPreview = true;
      } else {
        ctx.emit("callbackChanePage", type);
      }
    };

    const filePreview = (fileSrc) => {
      state.showFilePreview = true;
      state.fileUrl = fileSrc;
    };

    // 上传图片前处理
    const beforeRead = (file, detail) => {
      if (file.type != "image/jpeg" && file.type != "image/png") {
        Toast.fail("请上传 jpeg/png 格式图片");
        return false;
      }
      imageToBase64(file, detail);
    };

    const imageToBase64 = (file, detail) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        isShowCro.value = true;
        state.cutOption.img = reader.result;
        state.cutOption.detail = detail;
        state.showCropper = true;
        option.value.imgSrc = reader.result;
      };
    };

    // 点击上传备注图片区域
    let showBtnType = ref(false);
    let questionId = ref(0);
    let clickUploaderIndex = ref(0);
    const clickUploader = (index) => {
      showBtnType.value = true;
      clickUploaderIndex.value = index;
      questionId.value = state.questionList[index].id;
    };

    let imgPath = ref("");

    // 备注文本框失焦
    const inputBlur = async (index) => {
      let formData = new FormData();
      formData.append("case_id", route.query.id);
      formData.append(
        "remarks",
        state.questionList[clickUploaderIndex.value].remarks.remarks
      );
      formData.append(
        "image_path",
        imgPath.value
          ? imgPath.value
          : state.questionList[index].remarks.image_path
      );
      formData.append(
        "question_id",
        questionId.value ? questionId.value : state.questionList[index].id
      );
      let res = await apiSaveQuestionRemarks(formData);
    };

    const changeMassage = (e) => {
      massage.value = e;
    };

    const closImg = () => {
      ctx.emit("changeMsgImgPath");
    };

    let isShowCro = ref(false);

    let option = ref({
      imgSrc: "@/assets/new.png",
      autoCrop: true,
      dialogVisible: false,
      img_size: "",
      max_fuyuan_defaultSrc: [],
      // cropperAspectWH: 1, //图片剪裁宽高比例
      reader: "",
      e_photo_file: "",
      e_photo_filefile: "",
      http_cropImg: "", //带http
      cropImg: "", //默认图片不带http
      detail: null,
      itemIndex: null,
      keyName: null,
    });
    const changeIsShowCro = (op) => {
      isShowCro.value = true;
      option.value = op;
    };

    const lookAnswer = () => {};

    const changeSelect = (e) => {
      ctx.emit("selectAnswer", e);
    };
    const changeSelect2 = (e) => {
      ctx.emit("selectAnswer2", e);
    };

    return {
      ...toRefs(state),
      isShowCro,
      option,
      changeIsShowCro,
      openPreview,
      showText,
      callbackForCut,
      onClickLeft,
      getCropBlob,
      cropper,
      changePage,
      delItem,
      filePreview,
      showOption,
      massage,
      inputBlur,
      route,
      imgPath,
      beforeRead,
      clickUploader,
      changeMassage,
      closImg,
      lookAnswer,
      changeSelect,
      changeSelect2,
    };
  },
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";
.correction {
  // padding-top: 10px !important;
}

.fnBtn {
  position: absolute;
  width: 100%;
  top: 0;
}

.qsList {
  padding-right: 4px;

  .fun-area {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50px;
    .btnBox {
      margin-right: 1vw;
      // display: inline-block;
      float: right;
    }
  }
}

.upImgBox {
  height: 100px;
  text-align: center;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
  position: relative;
  .crossIcon {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    color: #fff;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.swipe-content {
  padding-bottom: 60px;
  position: relative;

  .qa-area {
    padding: 0 10px;
    border-bottom: 1px dashed #c7c7c7;

    .qa-area-content {
      padding: 10px;
      padding-top: 27px;
      // height: 98px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      background-color: #f7f8fa;
      margin-bottom: 10px;

      .question-text {
        line-height: 14px;
        height: 100%;
        overflow: hidden;
      }

      .question-file {
        height: 100%;
        overflow: hidden;
      }

      .imgBox {
        width: 100%;
        height: 100%;
        padding-bottom: 20px;
      }

      .title {
        border-radius: 4px;
        line-height: 18px;
        padding: 4px 14px;
        color: #fff;
        background-color: #7c7c7c;
        opacity: 0.8;
      }

      .positionL {
        position: absolute;
        left: 0;
        top: 0;
      }
      .positionR {
        position: absolute;
        right: 0;
        bottom: 0 !important;
        z-index: 2;
      }
    }
    .qa-area-content1 {
      padding: 10px;
      padding-top: 30px;
      height: 98px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      background-color: #f7f8fa;
      margin-bottom: 10px;

      .question-text {
        line-height: 14px;
        height: 100%;
        overflow: hidden;
      }

      .question-file {
        height: 100%;
        overflow: hidden;
      }

      .imgBox {
        width: 100%;
        height: 100%;
      }

      .title {
        border-radius: 4px;
        line-height: 18px;
        padding: 4px 14px;
        color: #fff;
        background-color: #7c7c7c;
        opacity: 0.8;
      }

      .positionL {
        position: absolute;
        left: 0;
        top: 0;
      }
      .positionR {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    }

    .forFile {
      height: 300px;
    }
  }
}

.icon {
  color: #dcdee0;
  font-size: 38px;
  text-align: center;
  padding-top: 1vw;
}

/* 竖屏 */
@media screen and (orientation: portrait) {
}

/* 横屏 */
@media screen and (orientation: landscape) {
}

.messageBox {
  height: auto !important;
  // display: flex;
}
</style>
<style lang="less">
/* 竖屏 */
@media screen and (orientation: portrait) {
  .qsList .van-image-preview {
    top: 30px;
    height: calc(100% - 30px - 40px);
  }

  .qsList .van-overlay {
    top: 30px;
    bottom: 40px;
    height: calc(100vh - 30px - 40px) !important;
  }

  .qsList .van-popup--top {
    top: 30px;
    bottom: 40px;
    height: calc(100vh - 30px - 40px) !important;
  }
}

/* 横屏 */
@media screen and (orientation: landscape) {
  .qsList .van-image-preview {
    top: 30px;
    left: 50px;
    width: calc(100% - 50px);
    height: calc(100% - 30px - 20px);
  }

  .qsList .van-overlay {
    top: 30px;
    bottom: 20px;
    left: 50px;
    height: calc(100vh - 30px - 20px) !important;
    width: calc(100vw - 50px) !important;
  }

  .qsList .van-popup--top {
    top: 30px;
    bottom: 20px;
    left: 50px;
    height: calc(100vh - 30px - 20px) !important;
    width: calc(100vw - 50px) !important;
  }
}

.qsList .van-field__label {
  font-size: 10px;
  width: 120px;
  white-space: nowrap;
}
.qsList .van-radio__label {
  font-size: 10px;
}

.cropper-container {
  height: calc(100% - 50px);
  .van-nav-bar {
    background-color: #000;
    opacity: 0.87;
    :global(.van-nav-bar__text) {
      color: #fff;
    }
    :global(.van-nav-bar__text:nth-child(2)) {
      color: #000;
      font-weight: 500;
    }
  }
}
.vue-cropper {
  background-color: #000;
  box-sizing: border-box;
}

.qsList textarea {
  font-size: 10px;
}

.qsList .descOverlay {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 100%;
}
.qsList .descOverlay .block {
  background-color: #fff;
  line-height: 3vh;
  width: 90vw;
  max-height: 80vh;
  border-radius: 8px;
  padding: 8px;
  overflow-y: auto;
  white-space: pre-wrap;
}

.qsList .descOverlay .file {
  height: 500px;
}

.qsList .fileOverlay {
  margin: 10px auto;
  width: 90%;
  height: calc(100% - 20px);
  background-color: #fff;
}
</style>
