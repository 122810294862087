<template>
  <div class="uaCut">
    <van-uploader
      v-if="fileArr.length < 1"
      v-model="files"
      :accept="accept"
      :capture="capture"
      :max-count="maxCount"
      :before-read="beforeRead"
      :style="{ width: width, height: height }"
    >
      <div
        v-if="isCustomPreview"
        class="uploadBtn"
        :style="{ width: width, height: height }"
      >
        <div class="icon"><van-icon name="photograph" /></div>
        <div class="tips">{{ btnTips }}</div>
      </div>
    </van-uploader>

    <div
      v-else
      class="imgPreview"
      :style="{ width: width, height: height }"
      v-for="(pitem, i) in fileArr"
      :key="i"
    >
      <div class="previewItem">
        <van-image
          width="100%"
          height="100%"
          fit="cover"
          :src="pitem"
          @click="openPreview(pitem)"
        />
      </div>
      <div class="delIcon" @click="delFile()">
        <van-icon name="cross" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { Toast, Dialog } from "vant";
// import { VueCropper } from 'vue-cropper'
export default {
  components: {
    // VueCropper
  },
  props: {
    accept: {
      type: String,
      default: "image/*",
    },
    capture: {
      type: String,
      default: "",
    },
    maxCount: {
      type: Number,
      default: 1,
    },
    fileArr: {
      type: Array,
      default: () => [],
    },
    isCustomPreview: {
      type: Boolean,
      default: false,
    },
    previewType: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    btnTips: {
      type: String,
      default: "",
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
    keyName: {
      type: String,
      default: "",
    },
  },
  emits: ["callbackForCut", "openPreview", "delItem", "changeIsShowCro"],
  setup(props, ctx) {
    const refProps = toRefs(props);

    const state = reactive({
      accept: refProps.accept,
      capture: refProps.capture,
      maxCount: refProps.maxCount,
      fileArr: refProps.fileArr,
      isCustomPreview: refProps.isCustomPreview,
      previewType: refProps.previewType,
      width: refProps.width,
      height: refProps.height,
      btnTips: refProps.btnTips,
      itemIndex: refProps.itemIndex,
      keyName: refProps.keyName,

      showCropper: false,
      loadingShow: false,

      option: {
        imgSrc: "@/assets/new.png",
        autoCrop: true,
        dialogVisible: false,
        img_size: "",
        max_fuyuan_defaultSrc: [],
        // cropperAspectWH: 1, //图片剪裁宽高比例
        reader: "",
        e_photo_file: "",
        e_photo_filefile: "",
        http_cropImg: "", //带http
        cropImg: "", //默认图片不带http
        detail: null,
        itemIndex: null,
        keyName: null,
      },

      files: [],
      uploaderData: [],
      previewImgs: [],

      itemIndexCalc: "",
    });

    const beforeRead = (file, detail) => {
      if (file.type != "image/jpeg" && file.type != "image/png") {
        Toast.fail("请上传 jpeg/png 格式图片");
        return false;
      }
      imageToBase64(file, detail);
    };

    const imageToBase64 = (file, detail) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        state.option.imgSrc = reader.result;
        state.option.detail = detail;
        state.option.itemIndex = state.itemIndex;
        state.option.keyName = state.keyName;
        ctx.emit("callbackForCut", state.option);
      };
    };

    const delFile = () => {
      Dialog.confirm({
        message: "是否删除，删除后无法回复！",
      })
        .then(() => {
          // state.fileArr.splice(index, 1)
          ctx.emit("delItem", state.itemIndex, state.keyName);
        })
        .catch(() => {});
    };

    const openPreview = (url) => {
      ctx.emit("openPreview", "我的提交", url);
    };

    return {
      ...toRefs(state),
      beforeRead,
      delFile,
      openPreview,
    };
  },
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";

.uaCut {
  width: 100%;
  height: 100%;
  .imgPreview {
    position: relative;
    .previewItem {
      width: 100%;
      height: 100%;
    }
    .delIcon {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 24px;
      line-height: 24px;
      color: #fff;
      background-color: #000;
      opacity: 0.7;
      text-align: right;
      padding-right: 4px;
      border-radius: 0 0 0 24px;
    }
  }
  .uploadBtn {
    text-align: center;
    .icon {
      color: #dcdee0;
      font-size: 38px;
      text-align: center;
      padding-top: 1vw;
    }
    .tips {
      color: #bdbdbd;
      text-align: center;
    }
  }
}
</style>
<style>
.uaCut .van-uploader__wrapper {
  width: 100%;
  height: 100%;
}
.uaCut .van-uploader__input-wrapper {
  width: 100%;
  height: 100%;
}
</style>
